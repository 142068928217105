<script>
export default {
  name: "page-text",
  props: {
    mainTitle: {
      type: String,
      required: true,
    },
    mainText: {
      type: String,
      required: true,
    },
    lastText: {
      type: String,
      required: true,
    }
  }
}
</script>

<template>
  <div class="">
    <h3 class="text-dark font-weight-bold">{{ mainTitle}}</h3>
    <p class="text-muted mb-2">{{ mainText }}</p>
    <p class="text-muted">{{ lastText }}</p>
    <router-link to="/" class="btn btn-primary mt-2">Retour à l'accueil</router-link>
  </div>
</template>

<style scoped>

</style>