<script>
export default {
  name: "svg-icon",
  props: {
    svgIcon: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div class="my-2">
    <img class="svg-icon" :src="svgIcon" alt="icon">
  </div>
</template>

<style scoped>
  .svg-icon{
    margin: 0 auto;
    height: 150px;
    width: 150px;
  }
</style>