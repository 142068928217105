<script>
import canceled from "../../assets/images/svg/canceled.svg"
import SvgIcon from "../../components/dashboard/payment/svg-icon.vue";
import PageText from "../../components/dashboard/payment/page-text.vue";
export default {
  name: "canceled",
  components: {PageText, SvgIcon},
  data() {
    return {
      svgIcon: canceled,
    };
  },
}
</script>

<template>
  <div class="payment-page">
    <div class="container">
      <div class="card shadow-sm p-4">
        <div class="text-center">
          <svg-icon :svg-icon="svgIcon" />
          <page-text
              main-title="Paiement annulé !"
              main-text="Votre paiement n’a pas été effectué."
              last-text="Veuillez réessayer ou contacter le support."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.payment-page{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>